<template>
  <v-container>
    <v-dialog v-model="formMain.show" persistent max-width="600px">
      <v-form v-on:submit.prevent ref="form-main" v-model="formMain.valid">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="headline">{{formMain.isAddMode ? 'Add' : 'Edit'}} Assets</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field label="Name *" v-model="formMain.model.name" :rules="formMain.rules.name" v-on:keydown.enter="saveForm"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete label="Category" v-model="formMain.model.category_id" :rules="formMain.rules.category_id" :items="formItems.categorys" item-text="name" item-value="id" v-on:keydown.enter="saveForm"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete label="Owner" v-model="formMain.model.owner_id" :rules="formMain.rules.owner_id" :items="formItems.users" item-text="name" item-value="id" :loading="formMain.loading.owner" v-on:update:search-input="onUpdateSearchInputOwner">
                    <template slot="prepend-inner">
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="formMain.showScannerOwner = !formMain.showScannerOwner" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                        </template>
                        <span>Scan Code</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <StreamBarcodeReader v-if="formMain.showScannerOwner" v-on:decode="onScannerDecodeOwner"></StreamBarcodeReader>
                </v-col>
                <v-col cols="12">
                  <v-menu v-model="formMain.showPurchaseDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field label="Purchase Date" v-model="computedPurchaseDate" :rules="formMain.rules.purchase_date" hint="YYYY-MM-DD format" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="formMain.model.purchase_date" no-title v-on:input="formMain.showPurchaseDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Code" v-model="formMain.model.code" :rules="formMain.rules.code" :loading="formMain.loading.code">
                    <template slot="prepend-inner">
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="formMain.showScanner = !formMain.showScanner" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                        </template>
                        <span>Scan Code</span>
                      </v-tooltip>
                    </template>
                    <template slot="append">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="getRandomCode" v-bind="attrs" v-on="on">mdi-refresh</v-icon>
                        </template>
                        <span>Generate Code</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <StreamBarcodeReader v-if="formMain.showScanner" v-on:decode="onScannerDecodeCode"></StreamBarcodeReader>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete label="Status *" v-model="formMain.model.status_id" :rules="formMain.rules.status_id" :items="formItems.status" item-text="name" item-value="id" v-on:keydown.enter="saveForm"></v-autocomplete>
                </v-col>
                
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text v-on:click="formMain.show = false">
              Cancel
            </v-btn>
            <v-btn v-if="formMain.isAddMode" color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Add
            </v-btn>
            <v-btn v-else color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dgDelete.show" max-width="300">
      <v-card>
        <v-card-title class="headline">
          Delete?
        </v-card-title>
        <v-card-text>Are you sure to delete asset: {{dgDelete.item ? dgDelete.item.name : '--'}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text v-on:click="dgDelete.show = false">
            Cancel
          </v-btn>
          <v-btn color="error" v-on:click="removeItem(dgDelete.item)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-btn text v-on:click="navTo('/Assets')" ><v-icon left>mdi-chevron-left</v-icon>Back to Assets Category</v-btn>
    </v-row>
    <v-row class="my-1">
      <v-col cols="12" sm="3">
        <v-btn elevation="4" color="success" block v-on:click="addItem"><v-icon left>mdi-plus</v-icon>Add</v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="items" item-key="name" :search="search" v-model="selected" :show-select="false">
      <template v-slot:top>
        <v-card color="primary white--text pa-2">
          <v-row align="center" no-gutters>
            <v-col cols="12" sm="8">
              <v-card-title>Assets</v-card-title>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="search" clearable flat solo hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
            </v-col>
          </v-row>

        </v-card>
      </template>
      <template v-slot:item.purchase_date="{ item }">
        <span>{{formatD(item.purchase_date)}}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="editItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="confirmRemove(item)" v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions-view="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on:click="viewItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:body.append>
        <tr>
          <td></td>
          <td>
            <v-text-field v-model="calories" type="number" label="less"></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
var moment = require('moment-timezone');

export default {
  data: function() {
    return {
      id: null,
      search: "",
      headers: [
        { text: 'Asset', value: 'name', width: '20%' },
        { text: 'Category', value: 'category.name' },
        { text: 'Owner', value: 'owner.name' },
        { text: 'Purchase Date', value: 'purchase_date' },
        { text: 'Code', value: 'code' },
        { text: 'Status', value: 'status.name' },
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' }
      ],
      items: [],
      selected: [],
      editingItem: null,
      dgDelete: {
        show: false,
        item: null,
      },
      formItems: {
        categorys: [],
        users: [],
        status: [],
      },
      formMain: {
        show: false,
        isAddMode: true,
        valid: false,
        showPurchaseDateMenu: false,
        showScanner: false,
        showScannerOwner: false,
        loading: {
          owner: false,
          code: false
        },
        model: {
          id: null,
          name: null,
          category_id: null,
          owner_id: null,
          purchase_date: null,
          code: null,
          status_id: 1001,
          category: null,
          owner: null,
          status: null,
        },
        rules: {
          name: [
            v => !!v || 'Required'
          ],
          purchase_date: [
            v => !v || moment(v).isValid() || 'Date Format Required'
          ],
          code: [
            v => !v || v.length == 16 || 'Code must be 16 digits'
          ]
        }
      },
    }
  },
  computed: {
    computedPurchaseDate: {
      set: function(val) {
        if(val == '' || val == null)
          this.formMain.model.purchase_date = null;
        else if(moment(val).isValid())
          this.formMain.model.purchase_date = moment(val).format();
      },
      get: function() {
        return this.formatD(this.formMain.model.purchase_date);
      }
    }
  },
  created() {
    if(this.$route.params.id)
      this.id = this.$route.params.id;
  },
  mounted() {
    this.getAllItems();
    this.getExtraItems();
  },
  methods: {
    getAllItems: function() {
      var _this = this;
      var url = '/asset/getall'
      if(this.id){
        if(this.id == -1)
          url = '/asset/getByCategoryID'
        else
          url = '/asset/getByCategoryID/' + this.id
      }
      this.get(url, function(res){
        _this.items = res['data']['data']
      })
    },
    getExtraItems: function() {
      var _this = this;
      this.get('/assetscategory/getall', function(res){
        _this.formItems.categorys = res['data']['data']
      })
      this.get('/status/getall', function(res){
        _this.formItems.status = res['data']['data']
      })
      this.get('/user/getall', function(res){
        _this.formItems.users = res['data']['data']
      })
    },
    getRandomCode: function() {
      var _this = this;
      this.formMain.loading.code = true;
      this.get('/asset/getRandomCode', function(res){
        _this.formMain.model.code = res['data']['data']
        _this.formMain.loading.code = false;
      }, function(){
        _this.formMain.loading.code = false;
      }, {showSuccess: false, showError: true, showLoading: false})
    },
    addItem: function() {
      this.formMain.isAddMode = true;
      this.formMain.show = true;
      this.resetForm();
    },
    saveForm: function() {
      if (!this.formMain.valid) return;

      var url = '/asset/add';
      if(!this.formMain.isAddMode)
        url = '/asset/update/' + this.formMain.model.id;

      var _this = this;
      this.formMain.show = false;
      this.post(url, this.formMain.model, function(){
        _this.formMain.show = false;
        _this.getAllItems();
        _this.resetForm();
      }, function(){
        _this.formMain.show = true;
      }, {showSuccess: true, showError: true, showLoading: true});
    },
    resetForm: function() {
      this.formMain.model = {
        id: null,
        name: null,
        category_id: null,
        owner_id: null,
        purchase_date: null,
        code: null,
        status_id: 1001,
        category: null,
        owner: null,
        status: null,
      }
      this.formMain.showPurchaseDateMenu = false
      this.formMain.showScanner = false
      this.formMain.showScannerOwner = false

      try {
        this.$refs['form-main'].resetValidation();
      }catch(error){
        //
      }
    },
    editItem: function(item) {
      this.formMain.show = true;
      this.resetForm();
      this.formMain.isAddMode = false;
      this.editingItem = JSON.parse(JSON.stringify(item));
      this.formMain.model = this.editingItem;
    },
    confirmRemove: function(item) {
      this.dgDelete.item = item;
      this.dgDelete.show = true;
    },
    removeItem: function(item) {
      var _this = this;
      this.dgDelete.show = false;
      
      this.post('/asset/delete/' + item.id, null, function(){
        _this.getAllItems();
      })
    },
    viewItem: function(item) {
        this.navTo(item);
    },
    onScannerDecodeCode: function(result) {
      // var _this = this;
      this.formMain.model.code = result
      this.formMain.showScanner = false;
    },
    onScannerDecodeOwner: function(result) {
      // var _this = this;
      // this.formMain.model.code = result
      this.onUpdateSearchInputOwner(result);
      this.formMain.showScannerOwner = false;
    },
    onUpdateSearchInputOwner: function(e) {
      if(!e) return;
      var code = e.match(/\d/g);
      if(!code) return;
      code = code.join('')
      console.log(code);
      if(code.length != 16) return;
      var _this = this;
      this.formMain.loading.owner = true;
      this.get('/user/getByCode/' + code, function(res){
        if(res['data']['data']){
          _this.formMain.model.owner = null
          _this.formMain.model.owner_id = null
          _this.$nextTick(() => {
            _this.formMain.model.owner = res['data']['data']
            _this.formMain.model.owner_id = _this.formMain.model.owner.id
          })
        }
        _this.formMain.loading.owner = false;
      }, function(){
        _this.formMain.loading.owner = false;
      }, {showSuccess: false, showError: true, showLoading: false})
    }
  }
}
</script>